export default {
  "sections": "qRz",
  "actions": "qRa",
  "search-input": "qRH",
  "no-upload": "qRO",
  "new-link": "qRm body-2",
  "between-links": "qRp",
  "action-bar": "qRx",
  "empty-state": "qRw"
};
