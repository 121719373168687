export default {
  "y-axis-placeholder": "cLu",
  "y-axis-navigable": "cTS",
  "y-axis-tick": "cTc",
  "x-axis-placeholder": "cTq",
  "x-axis-navigable": "cTZ",
  "x-axis-tick-wrapper": "cTR",
  "x-axis-tick": "cTQ",
  "chart-loading-renderer": "cTe"
};
