export default {
  "attachment-viewer": "cue",
  "hide-sidebar": "cuB",
  "sidebar": "cuE",
  "header": "cud",
  "header-top": "cuI",
  "header-icon": "cut",
  "header-amount": "cuM",
  "infobox": "cuP",
  "vat": "cul",
  "validation": "cuX"
};
