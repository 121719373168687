export default {
  "container": "ciG",
  "form-container": "cir",
  "content": "cib",
  "fields": "ciy",
  "input-settings": "ciU",
  "current-year": "cij",
  "next-invoice-number": "ciW",
  "preview-container": "ciu",
  "actions": "cAS",
  "close-button": "cAc btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cAq",
  "preview-image": "cAZ"
};
