export default {
  "wrapper": "cHI",
  "header": "cHt",
  "fadeIn": "cHM",
  "header-title": "cHP title-4",
  "transactions-container": "cHl",
  "placeholder-title": "cHX",
  "transaction-item-list-container": "cHC",
  "transaction-item-list": "cHk",
  "item": "cHJ"
};
