export default {
  "wrapper": "cjp",
  "request-header": "cjx",
  "request": "cjw",
  "scheduled": "cjN",
  "scheduled-label": "cjv",
  "note": "cjo",
  "note-label": "cjn",
  "note-label-danger": "cji",
  "note-content": "cjA",
  "status": "cjY",
  "transfer-amount": "cjh"
};
