export default {
  "mileage-validation": "qcS",
  "actions": "qcc",
  "action-btn": "qcq",
  "approve-btn": "qcZ",
  "request-mileage-validation": "qcR",
  "account-selector": "qcQ",
  "has-error": "qce",
  "funds-disclaimer": "qcB",
  "account-options": "qcE",
  "account-balance": "qcd",
  "warnings": "qcI"
};
