export default {
  "row": "qZH",
  "active": "qZO",
  "dot": "qZm",
  "red": "qZp",
  "green": "qZx",
  "yellow": "qZw",
  "status": "qZN",
  "align-right": "qZv",
  "animated": "qZo",
  "fadein-item": "qZn",
  "cell": "qZi body-2",
  "cell-content": "qZA",
  "amount": "qZY body-2",
  "subtitle": "qZh caption",
  "padding-left": "qZf",
  "padding-right": "qZK",
  "note": "qZG"
};
