export default {
  "card": "cLI",
  "header": "cLt",
  "avatar": "cLM",
  "title": "cLP",
  "subtitle": "cLl",
  "label": "cLX",
  "item": "cLC body-2",
  "header-icon": "cLk",
  "dropdown-menu": "cLJ",
  "menu-item": "cLs body-2",
  "delete": "cLF"
};
