export default {
  "details-box": "cVE",
  "picto": "cVd",
  "picto-status": "cVI",
  "ghost": "cVt",
  "primary-info": "cVM",
  "date": "cVP",
  "account-infobox": "cVl",
  "declined-disclaimer": "cVX",
  "nrc-download": "cVC"
};
