export default {
  "mileage-validation": "cGZ",
  "actions": "cGR",
  "action-btn": "cGQ",
  "action-btn-success": "cGe",
  "action-btn-right": "cGB",
  "request-mileage-validation": "cGE",
  "account-selector": "cGd",
  "has-error": "cGI",
  "funds-disclaimer": "cGt",
  "account-options": "cGM",
  "account-balance": "cGP",
  "warnings": "cGl"
};
