export default {
  "account-container": "cHn",
  "badge": "cHi",
  "subtitle": "cHA title-4",
  "accounts-filter": "cHY",
  "account-filter-label": "cHh",
  "status": "cHf",
  "account-info": "cHK",
  "balance": "cHG",
  "revealed-balance": "cHr",
  "hidden-balance": "cHb"
};
