export default {
  "wrapper": "cxx",
  "per-page-container": "cxw",
  "options": "cxN",
  "option": "cxv",
  "active": "cxo",
  "description": "cxn",
  "description-per-page": "cxi",
  "summary": "cxA"
};
