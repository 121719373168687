export default {
  "row": "qkB",
  "active": "qkE",
  "dot": "qkd",
  "red": "qkI",
  "green": "qkt",
  "yellow": "qkM",
  "align-right": "qkP",
  "animated": "qkl",
  "fadein-item": "qkX",
  "cell": "qkC body-2",
  "cell-content": "qkk",
  "amount": "qkJ body-1",
  "subtitle": "qks caption",
  "status": "qkF",
  "cell-amount": "qkg"
};
