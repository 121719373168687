export default {
  "row": "cxB",
  "cell": "cxE",
  "title": "cxd",
  "internal-note": "cxI",
  "unit": "cxt",
  "price": "cxM",
  "quick-actions": "cxP",
  "empty": "cxl",
  "checkbox": "cxX"
};
