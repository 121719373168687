export default {
  "gap-32": "cvX",
  "fieldset": "cvC",
  "date-picker": "cvk",
  "with-performance-date": "cvJ",
  "mt-4": "cvs",
  "clients": "cvF",
  "dropdown-option": "cvg",
  "dropdown-content": "cvD",
  "select-client": "cvV",
  "client-option": "cvL",
  "icon-tooltip": "cvT",
  "payment-details": "cvz",
  "dates-container": "cva",
  "automatic-number-label": "cvH",
  "label": "cvO",
  "numbering-container": "cvm",
  "fix-width": "cvp",
  "customer-error": "cvx",
  "amount-text": "cvw",
  "header-text-field-container": "cvN",
  "header-text-field": "cvv",
  "header-text-field-close-button": "cvo",
  "mt-16": "cvn"
};
