export default {
  "team-card": "qJR",
  "card-link": "qJQ",
  "shadow": "qJe",
  "history-button": "qJB",
  "cards-button": "qJE",
  "menu-item": "qJd",
  "subtitle": "qJI",
  "delete-team": "qJt"
};
