export default {
  "item-status": "cab",
  "item-date": "cay",
  "item-amount": "caU body-1",
  "item-amount-credit": "caj",
  "row": "caW",
  "icon": "cau",
  "disabled": "cHS",
  "icon-container": "cHc",
  "text-container": "cHq",
  "text-row": "cHZ",
  "item-details": "cHR",
  "counterparty-name": "cHQ",
  "column": "cHe",
  "item-amount-disabled": "cHB",
  "attachment-tooltip": "cHE",
  "bank-account-avatar": "cHd"
};
