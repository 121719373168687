export default {
  "banner": "cLw",
  "content": "cLN",
  "title-row": "cLv",
  "title": "cLo title-3",
  "badge": "cLn",
  "text": "cLi body-2",
  "missing-info-disclaimer": "cLA",
  "button": "cLY btn btn--primary",
  "spinner": "cLh",
  "image": "cLf"
};
