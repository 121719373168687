export default {
  "custom-period-wrapper": "czR",
  "fadeIn": "czQ",
  "custom-period-description": "cze body-2",
  "header": "czB",
  "arrow-left": "czE",
  "body": "czd",
  "period-wrapper": "czI",
  "back-button": "czt",
  "footer": "czM"
};
