export default {
  "container": "cnI",
  "text-content": "cnt",
  "text-wrapper": "cnM",
  "actions-container": "cnP",
  "illustration": "cnl",
  "feature-tour-link": "cnX",
  "link-illustration": "cnC",
  "lottie": "cnk"
};
