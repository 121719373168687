export default {
  "row": "qZq",
  "active": "qZZ",
  "dot": "qZR",
  "red": "qZQ",
  "green": "qZe",
  "yellow": "qZB",
  "status": "qZE",
  "align-right": "qZd",
  "animated": "qZI",
  "fadein-item": "qZt",
  "cell": "qZM body-2",
  "cell-content": "qZP",
  "amount": "qZl body-1",
  "subtitle": "qZX caption",
  "no-padding": "qZC"
};
