export default {
  "bank-account-option": "qkD",
  "row": "qkV",
  "active": "qkL",
  "animated": "qkT",
  "fadein-item": "qkz",
  "cell": "qka body-2",
  "cell-content": "qkH",
  "subtitle": "qkO caption",
  "no-padding": "qkm",
  "empty": "qkp",
  "note": "qkx",
  "cell-content-amount": "qkw body-1",
  "cell-content-receipt": "qkN body-1",
  "cell-quick-actions": "qkv",
  "account-selector": "qko",
  "cell-type": "qkn"
};
