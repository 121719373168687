export default {
  "wrapper": "qBD",
  "content": "qBV",
  "title": "qBL",
  "aside": "qBT",
  "description": "qBz",
  "faq-link": "qBa",
  "close-btn": "qBH",
  "container": "qBO",
  "mandate-field-list": "qBm",
  "mandate-field-item": "qBp",
  "mandate-field-item__dot": "qBx",
  "mandate-field-item__text": "qBw"
};
