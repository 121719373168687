export default {
  "cell": "qls",
  "cell-label-container": "qlF",
  "cell-label": "qlg",
  "overflow": "qlD",
  "sub-label": "qlV body-2",
  "overdue": "qlL",
  "file-name-warning": "qlT",
  "missing": "qlz",
  "warning-icon": "qla",
  "flex-label": "qlH",
  "with-separator": "qlO",
  "self-invoice-icon": "qlm"
};
