export default {
  "content": "cjQ",
  "balance": "cje",
  "duration": "cjB",
  "green-text": "cjE",
  "balance-subtitle": "cjd",
  "balance-duration": "cjI",
  "footer": "cjt",
  "progress": "cjM",
  "progress-bar": "cjP",
  "progress-bar-text": "cjl",
  "progress-active": "cjX"
};
