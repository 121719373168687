export default {
  "section-container": "cbV",
  "disclaimer-section": "cbL",
  "trip-title": "cbT caption-bold mb-16",
  "google-link-container": "cbz",
  "link-icon": "cba",
  "trip-details": "cbH body-2",
  "trip-details-icon": "cbO",
  "trip-details-title": "cbm",
  "subtitle": "cbp caption-bold mb-16",
  "disclaimer": "cbx",
  "visualizer": "cbw"
};
