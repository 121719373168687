export default {
  "navigation-dropdown": "cDB",
  "expanded": "cDE",
  "trigger": "cDd",
  "trigger-title-icon": "cDI",
  "collapsed": "cDt",
  "responsive": "cDM",
  "logo": "cDP",
  "active": "cDl",
  "trigger-title": "cDX",
  "content": "cDC",
  "content-container": "cDk",
  "dropdown-icon": "cDJ",
  "link": "cDs"
};
