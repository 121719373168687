export default {
  "header-cell": "qqp",
  "header-content": "qqx caption-bold",
  "active": "qqw",
  "empty": "qqN",
  "align-right": "qqv",
  "request-type": "qqo",
  "requester": "qqn",
  "amount": "qqi"
};
