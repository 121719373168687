export default {
  "sidebar": "ciQ",
  "body": "cie",
  "form": "ciB",
  "form-section": "ciE",
  "form-title": "cid title2",
  "row": "ciI",
  "payment-details": "cit",
  "col": "ciM",
  "footer": "ciP",
  "footer-button": "cil"
};
