export default {
  "iban": "qRI",
  "checkbox": "qRt",
  "toggle-content": "qRM",
  "beneficiary-name": "qRP",
  "category": "qRl",
  "beneficiary-email": "qRX",
  "beneficiary-form": "qRC",
  "beneficiary-email-container": "qRk"
};
