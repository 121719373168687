export default {
  "header-cell": "qCj",
  "header-content": "qCW caption-bold",
  "active": "qCu",
  "empty": "qkS",
  "type": "qkc",
  "requester": "qkq",
  "request-date": "qkZ",
  "amount": "qkR",
  "receipt": "qkQ",
  "quick-actions": "qke"
};
