export default {
  "sidepanel": "qlf",
  "close-icon": "qlK",
  "close-button": "qlG",
  "supplier-card": "qlr",
  "supplier-card-details": "qlb",
  "supplier-card-placeholder": "qly",
  "box": "qlU",
  "box-element": "qlj",
  "related-invoices-title": "qlW",
  "placeholder-container": "qlu",
  "related-invoices-wrapper": "qXS",
  "related-invoice-placeholder": "qXc",
  "related-invoice-placeholder-inner": "qXq",
  "actions": "qXZ"
};
