export default {
  "row": "qXJ",
  "cell": "qXs",
  "supplier": "qXF",
  "email-address": "qXg",
  "outstanding-balance": "qXD",
  "quick-actions": "qXV",
  "empty": "qXL",
  "supplier-container": "qXT",
  "supplier-avatar": "qXz",
  "grey-background": "qXa"
};
