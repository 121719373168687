export default {
  "header": "qcs",
  "header-type": "qcF",
  "close": "qcg",
  "close-icon": "qcD",
  "header-infos": "qcV",
  "title": "qcL body-1",
  "declined": "qcT",
  "canceled": "qcz",
  "approved": "qca",
  "date": "qcH body-2",
  "container": "qcO",
  "picto": "qcm",
  "request": "qcp",
  "picto-status": "qcx",
  "check": "qcw",
  "stop": "qcN",
  "warning": "qcv",
  "general": "qco",
  "amount": "qcn",
  "counterparty": "qci body-2",
  "initiator": "qcA",
  "initiator-avatar": "qcY",
  "avatar-image": "qch",
  "initiator-id": "qcf",
  "name": "qcK body-2",
  "role": "qcG caption-bold",
  "infobox": "qcr",
  "account-infobox": "qcb qcr",
  "beneficiary-warning": "qcy caption-bold",
  "dates-wrapper": "qcU",
  "dates": "qcj",
  "recurrence": "qcW caption-bold",
  "arrow": "qcu",
  "notify-checkbox": "qqS"
};
