export default {
  "progress-container": "qJD",
  "dashed-line": "qJV",
  "solid-line": "qJL",
  "circle-container": "qJT",
  "circle": "qJz",
  "current-step-dot": "qJa",
  "last-step-dot": "qJH",
  "success-icon": "qJO",
  "rejected-icon": "qJm"
};
