export default {
  "row": "qki",
  "icon": "qkA",
  "cell": "qkY",
  "cell-date": "qkh",
  "cell-amount": "qkf",
  "cell-status": "qkK",
  "cell-content": "qkG",
  "align-right": "qkr"
};
