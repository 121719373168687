export default {
  "section": "civ",
  "header-section": "cio",
  "header-title": "cin",
  "header-item": "cii",
  "item": "ciA",
  "center-align": "ciY",
  "content-gap": "cih",
  "cta-footer": "cif",
  "section-content": "ciK"
};
