export default {
  "fullscreen-modal": "cpI",
  "creation-form-modal": "cpt",
  "links-container": "cpM",
  "link-field": "cpP",
  "link": "cpl",
  "add-link": "cpX",
  "remove-link": "cpC",
  "flex-row": "cpk",
  "flex-item": "cpJ",
  "unit-trigger": "cps",
  "dynamic-placeholders": "cpF"
};
