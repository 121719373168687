export default {
  "modal": "cAJ",
  "action-container": "cAs",
  "similar-label": "cAF",
  "info-icon": "cAg",
  "container": "cAD",
  "subtitle": "cAV caption-bold",
  "error": "cAL",
  "item-container": "cAT"
};
