export default {
  "modal-wrapper": "cAo",
  "spinner-container": "cAn",
  "spinner": "cAi",
  "container": "cAA",
  "tabs-item": "cAY",
  "form-container": "cAh",
  "fieldset": "cAf",
  "preview-container": "cAK",
  "switch-container": "cAG",
  "pdf-preview-container": "cAr",
  "form-content": "cAb",
  "row": "cAy",
  "numbering-format": "cAU",
  "numbering-fields": "cAj",
  "input-settings": "cAW",
  "next-invoice-number": "cAu",
  "spacing": "cYS",
  "label-tooltip": "cYc",
  "tooltip-icon": "cYq",
  "customize-field": "cYZ",
  "button-container-footer": "cYR"
};
