export default {
  "row": "cKZ",
  "icon": "cKR",
  "cell": "cKQ",
  "cell-amount": "cKe",
  "cell-status": "cKB",
  "cell-date": "cKE",
  "cell-content": "cKd",
  "align-right": "cKI"
};
