export default {
  "subtitle": "cUa",
  "green-text": "cUH",
  "balance-subtitle": "cUO",
  "options": "cUm",
  "shadow": "cUp",
  "menu-item": "cUx body-2",
  "close-account": "cUw",
  "footer": "cUN",
  "progress": "cUv",
  "progress-bar": "cUo",
  "progress-bar-text": "cUn",
  "progress-active": "cUi"
};
