export default {
  "modal": "qBr",
  "container": "qBb",
  "form-container": "qBy",
  "preview-container": "qBU",
  "preview-content": "qBj",
  "switch-container": "qBW",
  "supplier-invoice-preview": "qBu",
  "header": "qES",
  "footer": "qEc",
  "form-content": "qEq",
  "form-content-section": "qEZ",
  "form-disclaimer": "qER",
  "hidden": "qEQ"
};
