export default {
  "row": "cyo",
  "active": "cyn",
  "dot": "cyi",
  "red": "cyA",
  "green": "cyY",
  "yellow": "cyh",
  "status": "cyf",
  "align-right": "cyK",
  "animated": "cyG",
  "fadein-item": "cyr",
  "cell": "cyb body-2",
  "cell-content": "cyy",
  "amount": "cyU body-2",
  "subtitle": "cyj caption",
  "padding-left": "cyW",
  "padding-right": "cyu",
  "note": "cUS"
};
