export default {
  "credit-note-sidebar": "cNM",
  "body": "cNP",
  "box": "cNl",
  "box-header": "cNX",
  "footer": "cNC",
  "footer-button": "cNk",
  "sidebar-box": "cNJ",
  "border-top": "cNs",
  "row": "cNF",
  "related-invoice-title": "cNg caption-bold",
  "einvoice-rejected": "cND"
};
