export default {
  "banner": "cVW",
  "inner": "cVu",
  "header": "cLS title-3",
  "content": "cLc",
  "subtitle": "cLq body-1",
  "list": "cLZ",
  "item": "cLR body-1",
  "icon": "cLQ",
  "cta": "cLe btn btn--primary",
  "spinner": "cLB",
  "illustration": "cLE",
  "image": "cLd"
};
