export default {
  "account-description": "cOU",
  "account-identification": "cOj",
  "account-avatar": "cOW",
  "account-name": "cOu",
  "account-balance": "cmS body-2",
  "status": "cmc",
  "external-accounts-disclaimer": "cmq",
  "failed-connection": "cmZ"
};
