export default {
  "section-container": "qSX",
  "disclaimer-section": "qSC",
  "trip-title": "qSk caption-bold mb-16",
  "google-link-container": "qSJ",
  "link-icon": "qSs",
  "trip-details": "qSF body-2",
  "trip-details-icon": "qSg",
  "trip-details-title": "qSD",
  "subtitle": "qSV caption-bold mb-16",
  "disclaimer": "qSL",
  "visualizer": "qST"
};
