export default {
  "form": "qMz",
  "form-section": "qMa",
  "form-title": "qMH title2",
  "divider": "qMO",
  "row": "qMm",
  "col": "qMp",
  "actions": "qMx",
  "buttons": "qMw"
};
