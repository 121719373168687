export default {
  "card-sidebar-header": "cWN",
  "content": "cWv",
  "header-type": "cWo",
  "close": "cWn",
  "close-icon": "cWi",
  "header-infos": "cWA",
  "title": "cWY body-1",
  "danger": "cWh",
  "success": "cWf",
  "warning": "cWK",
  "date": "cWG body-2",
  "card-image-container": "cWr",
  "card-image": "cWb",
  "info-container": "cWy",
  "spending-reason": "cWU",
  "spending-reason-label": "cWj body-2 mr-8",
  "spending-reason-note": "cWW body-2"
};
