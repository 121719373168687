export default {
  "card": "cmO",
  "card-primary": "cmm",
  "content": "cmp",
  "content-primary": "cmx",
  "disabled": "cmw",
  "illustration": "cmN",
  "illustration-primary": "cmv",
  "illustration-disabled": "cmo",
  "cta": "cmn",
  "dismiss-button": "cmi",
  "buttons": "cmA",
  "required-badge": "cmY"
};
