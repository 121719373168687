export default {
  "label-select": "qdA",
  "puce": "qdY",
  "puce-wrapper": "qdh",
  "puce-container": "qdf",
  "readonly": "qdK",
  "label-display": "qdG",
  "label-list-name": "qdr body-1",
  "label-list-value": "qdb body-2"
};
