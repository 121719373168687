export default {
  "header-cell": "cYm",
  "empty": "cYp",
  "header-content": "cYx caption-bold",
  "active": "cYw",
  "align-right": "cYN",
  "col-8": "cYv",
  "col-7": "cYo",
  "col-5": "cYn",
  "col-4": "cYi"
};
