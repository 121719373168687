export default {
  "wrapper": "cfI",
  "tabs": "cft",
  "tabs-nav": "cfM",
  "tabs-panel-wrapper": "cfP",
  "list-head": "cfl",
  "list": "cfX",
  "footer": "cfC",
  "reward-amount": "cfk",
  "earned": "cfJ",
  "pending": "cfs",
  "empty-state-wrapper": "cfF",
  "empty-state": "cfg",
  "illustration": "cfD",
  "error-wrapper": "cfV",
  "error-illustration": "cfL",
  "error-description": "cfT",
  "spinner": "cfz",
  "total-earned": "cfa"
};
