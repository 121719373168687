export default {
  "row": "qZk",
  "active": "qZJ",
  "animated": "qZs",
  "fadein-item": "qZF",
  "cell": "qZg body-2",
  "cell-content": "qZD",
  "subtitle": "qZV caption",
  "no-padding": "qZL",
  "note": "qZT",
  "cell-amount": "qZz",
  "cell-content-amount": "qZa body-1"
};
