export default {
  "savings-account": "qee",
  "closed": "qeB",
  "badge": "qeE",
  "subtitle": "qed",
  "amount": "qeI",
  "processing": "qet",
  "gain": "qeM",
  "active": "qeP",
  "progress": "qel",
  "progress-bar": "qeX",
  "progress-text": "qeC",
  "info": "qek",
  "menu-item": "qeJ body-2",
  "close-account": "qes"
};
