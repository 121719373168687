export default {
  "header-cell": "cGT",
  "empty": "cGz",
  "header-type": "cGa",
  "header-requester": "cGH",
  "header-date": "cGO",
  "header-amount": "cGm",
  "header-approval": "cGp",
  "header-content": "cGx caption-bold",
  "active": "cGw",
  "header-receipt": "cGN",
  "row-compact": "cGv",
  "row-sidebar": "cGo",
  "hidden": "cGn",
  "animated": "cGi",
  "fadein-item": "cGA",
  "animated-cell": "cGY"
};
