export default {
  "request-expense-report-validation": "qSq",
  "account-selector": "qSZ",
  "has-error": "qSR",
  "actions": "qSQ",
  "action-btn": "qSe",
  "action-btn-success": "qSB",
  "warnings": "qSE",
  "top-border": "qSd",
  "account-selector-subtitle": "qSI",
  "action-btn-right": "qSt",
  "funds-disclaimer": "qSM",
  "account-options": "qSP",
  "account-balance": "qSl"
};
