export default {
  "item-wrapper": "qIj",
  "title-sub-wrapper": "qIW",
  "icon-title-wrapper": "qIu",
  "icon": "qtS",
  "icon-wrapper": "qtc",
  "title": "qtq body-1",
  "subtitle": "qtZ body-2",
  "amount": "qtR body-1"
};
