export default {
  "container": "cOP",
  "label": "cOl",
  "disabled": "cOX",
  "small": "cOC caption",
  "large": "cOk body-2",
  "caption": "cOJ",
  "amount": "cOs",
  "right-aligned": "cOF",
  "expanded": "cOg",
  "amount--current": "cOD",
  "amount--total": "cOV",
  "amount--sep": "cOL"
};
