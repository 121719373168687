export default {
  "qonto-pilot-modal": "cwQ",
  "content": "cwe",
  "header": "cwB",
  "transition-state-container": "cwE",
  "lottie": "cwd",
  "prompt-input": "cwI",
  "buttons": "cwt",
  "footer": "cwM caption",
  "divider": "cwP",
  "illustration": "cwl",
  "icon": "cwX",
  "quick-actions-container": "cwC",
  "action": "cwk body-2",
  "action-title": "cwJ",
  "disabled": "cws",
  "action-tag": "cwF caption-bold",
  "hotkey": "cwg body-1",
  "footer-action": "cwD",
  "grow": "cwV",
  "footer-feedback-link": "cwL",
  "buttons-container": "cwT",
  "transfer-details-container": "cwz",
  "error-container": "cwa",
  "option-iban": "cwH",
  "actions": "cwO",
  "title": "cwm",
  "group-title": "cwp caption-bold",
  "no-result": "cwx",
  "lottie-illustration": "cww",
  "beta-badge": "cwN",
  "hotkeys": "cwv",
  "supplier-invoices-dropzone": "cwo",
  "success-icon": "cwn",
  "error-icon": "cwi",
  "file-items": "cwA",
  "file-item": "cwY",
  "uploader-file": "cwh"
};
