export default {
  "card": "cNf",
  "error": "cNK",
  "customer-info": "cNG",
  "edit-customer-tooltip": "cNr",
  "title": "cNb",
  "flex-row": "cNy",
  "dot-separator": "cNU",
  "flex-span": "cNj",
  "faq-link": "cNW",
  "tooltip-wrapper": "cNu"
};
