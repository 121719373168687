export default {
  "donut-chart-container": "cTP",
  "donut-chart-wrapper": "cTl",
  "chart": "cTX",
  "donut-chart-legend-container": "cTC caption",
  "donut-chart-legend-item-empty": "cTk",
  "donut-chart-legend-bullet-empty": "cTJ",
  "donut-chart-legend-bullet": "cTs",
  "donut-chart-legend-text-empty": "cTF",
  "donut-chart-legend-text": "cTg",
  "donut-chart-custom-label-uncategorized": "cTD",
  "custom-label-uncategorized-button": "cTV body-1",
  "donut-chart-legend": "cTL",
  "donut-chart-legend-item": "cTT",
  "donut-chart-legend-other-item": "cTz",
  "donut-chart-legend-uncategorized-item": "cTa",
  "donut-chart-legend-text-name": "cTH",
  "tooltip": "cTO"
};
