export default {
  "selector": "cvW",
  "pension-selector-content": "cvu",
  "withholding-tax-selector-content": "coS",
  "withholding-tax-selector-content-row": "coc",
  "power-select-field": "coq",
  "rate-field": "coZ",
  "stamp-revenue-field": "coR",
  "dropdown-option": "coQ",
  "dropdown-content": "coe"
};
