export default {
  "refunds-total": "qta",
  "switch-recurrence": "qtH",
  "flex-wrapper": "qtO",
  "promo-code": "qtm",
  "promo-code-total": "qtp",
  "others": "qtx",
  "others-total": "qtw",
  "subscription-detail": "qtN",
  "total-vat": "qtv",
  "fee-description": "qto"
};
