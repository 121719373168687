export default {
  "header-cell": "cfO",
  "header-content": "cfm caption-bold",
  "active": "cfp",
  "empty": "cfx",
  "request-type": "cfw",
  "date": "cfN",
  "requester": "cfv",
  "amount": "cfo",
  "header-status": "cfn"
};
