export default {
  "header-cell": "qXE",
  "first-col": "qXd",
  "mid-col": "qXI",
  "outstanding-col": "qXt",
  "header-content": "qXM caption-bold",
  "active": "qXP",
  "quick-actions": "qXl",
  "empty": "qXX"
};
