export default {
  "document": "cAd",
  "document-details": "cAI",
  "document-details-amount": "cAt body-2",
  "negative": "cAM",
  "positive": "cAP",
  "rejected": "cAl",
  "document-icon": "cAX",
  "document-details-link": "cAC body-link",
  "canceled": "cAk"
};
