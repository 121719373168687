export default {
  "request-transfer-validation": "qQt",
  "account-selector": "qQM",
  "has-error": "qQP",
  "actions": "qQl",
  "action-btn": "qQX",
  "warnings": "qQC",
  "top-border": "qQk",
  "account-selector-subtitle": "qQJ",
  "action-btn-right": "qQs",
  "funds-disclaimer": "qQF",
  "account-options": "qQg",
  "account-balance": "qQD",
  "request-multi-transfer-validation": "qQV",
  "wrapper": "qQL",
  "warning-message-row": "qQT",
  "multi-transfer-align": "qQz",
  "button-approve": "qQa",
  "button-approve-spinner": "qQH",
  "button-decline": "qQO"
};
