export default {
  "header-cell": "qqb",
  "header-content": "qqy caption-bold",
  "active": "qqU",
  "empty": "qqj",
  "request-type": "qqW",
  "status": "qqu",
  "note": "qZS",
  "amount": "qZc"
};
