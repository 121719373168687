export default {
  "card-details-container": "cWT",
  "title": "cWz caption-bold mb-16",
  "section": "cWa",
  "divider": "cWH",
  "top-divider": "cWO",
  "content": "cWm body-2",
  "description": "cWp",
  "justify-end": "cWx",
  "icon": "cWw ml-8"
};
