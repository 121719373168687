export default {
  "invoice-sidebar": "ciX",
  "body": "ciC",
  "box": "cik",
  "box-header": "ciJ",
  "footer": "cis",
  "footer-button": "ciF",
  "sidebar-box": "cig",
  "border-top": "ciD",
  "row": "ciV",
  "status-container": "ciL",
  "due-date-container": "ciT",
  "mark-as-btn": "ciz",
  "primary-actions": "cia cig",
  "primary-action": "ciH body-1",
  "button-icon": "ciO"
};
