export default {
  "notifications-settings": "cDv",
  "opened": "cDo",
  "icon": "cDn",
  "header": "cDi",
  "header-toggle": "cDA",
  "avatar-image": "cDY",
  "title": "cDh",
  "toggle": "cDf"
};
