export default {
  "item": "qPf",
  "details": "qPK",
  "flex": "qPG",
  "header": "qPr qPG",
  "flex-no-gap": "qPb qPG",
  "file-name": "qPy",
  "label": "qPU body-2",
  "self-icon": "qPj",
  "with-separator": "qPW",
  "dimmed": "qPu"
};
