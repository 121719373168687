export default {
  "attachment-viewer": "crJ",
  "hide-sidebar": "crs",
  "sidebar": "crF",
  "header": "crg",
  "header-top": "crD",
  "header-icon": "crV",
  "header-amount": "crL",
  "infobox": "crT",
  "vat": "crz",
  "validation": "cra"
};
