export default {
  "details": "qtK",
  "details-header": "qtG",
  "details-content": "qtr",
  "details-footer": "qtb",
  "invoice-status-container": "qty body-2",
  "invoice-payment-date": "qtU",
  "overdue": "qtj",
  "details-list": "qtW",
  "details-list-item": "qtu",
  "details-list-item-label": "qMS body-2",
  "details-list-item-value": "qMc",
  "border-top": "qMq",
  "description": "qMZ",
  "description-value": "qMR",
  "edit-button": "qMQ body-2",
  "edit-icon": "qMe",
  "icon": "qMB"
};
