/* import __COLOCATED_TEMPLATE__ from './blockers-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { codeToName } from 'qonto/models/subscription-product';

export default class BlockersModalComponent extends Component {
  @service intl;
  @service router;
  @service abilities;
  @service flowLinkManager;
  @service organizationManager;
  @service segment;

  constructor() {
    super(...arguments);
    let { organization } = this.subscription;

    if (!organization.get('bankAccounts.length')) {
      organization.loadBankAccounts();
    }
  }

  get hasModularPricing() {
    return this.organizationManager.organization.hasModularPricing;
  }

  get subscription() {
    return this.args.data.subscription;
  }

  get membershipsToRevokeCount() {
    return this.args.data.membershipsToRevokeCount;
  }

  get blockers() {
    return this.args.data.errors.map(error =>
      this.mapErrorToBlocker(error.code, error.additional_data)
    );
  }

  mapErrorToBlocker(code, additionalData) {
    let { intl } = this;
    let { pricePlan, organization } = this.subscription;

    switch (code) {
      case 'user_limit_reached': {
        let isDowngradingToAddAdminOnly;
        if (this.hasModularPricing) {
          isDowngradingToAddAdminOnly =
            this.abilities.cannot('add admin only member') &&
            // target plan is eligible to admins for solo
            additionalData.user_limit === 1 &&
            additionalData.plan_has_team_management_feature &&
            variation('feature--boolean-modular-pricing-admins-for-solo');
        } else {
          isDowngradingToAddAdminOnly =
            this.abilities.cannot('add admin only member') &&
            this.abilities.can('add admin only member', pricePlan);
        }
        return {
          link: 'members',
          title: intl.t('subscription.change.blockers.team.title', {
            count: this.membershipsToRevokeCount,
          }),
          linkText: intl.t('subscription.change.blockers.team.link'),
          description: isDowngradingToAddAdminOnly
            ? intl.t('subscription.change.blockers.team.subtitle-solo', {
                count: this.membershipsToRevokeCount,
              })
            : intl.t('subscription.change.blockers.team.subtitle', {
                count1: this.hasModularPricing
                  ? additionalData.user_limit
                  : pricePlan.get('userLimit'),
                count2: this.membershipsToRevokeCount,
              }),
        };
      }
      case 'accountant_access_disallowed': {
        let accountantsCountToDisable = organization.get(
          'accountantsCountingTowardsPlanLimitCount'
        );
        return {
          link: 'members',
          title: intl.t('subscription.change.blockers.accountant.title', {
            count: accountantsCountToDisable,
          }),
          linkText: intl.t('subscription.change.blockers.accountant.link'),
          description: intl.t('subscription.change.blockers.accountant.subtitle', {
            count: accountantsCountToDisable,
          }),
        };
      }

      case 'bank_account_limit_reached': {
        let bankAccountLimit = this.hasModularPricing
          ? additionalData.bank_account_limit
          : pricePlan.get('bankAccountLimit');
        return {
          link: 'accounts',
          title: intl.t('subscription.change.blockers.accounts.title', {
            count: this.bankAccountCountToDisable(bankAccountLimit),
          }),
          linkText: intl.t('subscription.change.blockers.accounts.link'),
          description: intl.t('subscription.change.blockers.accounts.subtitle', {
            count1: bankAccountLimit,
            count2: this.bankAccountCountToDisable(bankAccountLimit),
          }),
        };
      }

      case 'current_addon_not_supported_with_target_plan':
        return {
          link: { name: 'addon-change', recurrence: additionalData.addon_product_recurrence },
          title: intl.t('subscription.change.blockers.addon.title', {
            addonName: codeToName(intl, additionalData.addon_product_code),
          }),
          linkText: intl.t('subscription.change.blockers.addon.link'),
          description: intl.t('subscription.change.blockers.addon.subtitle'),
          blockerTrackEvent: {
            blockerType: 'removal',
            addon_code: additionalData.addon_product_code,
          },
        };

      case 'current_addon_recurrence_not_supported_with_target_plan_recurrence':
        return {
          link: { name: 'addon-change', recurrence: additionalData.addon_product_recurrence },
          title: intl.t('subscription.change.blockers.addon-billing.title', {
            addonName: codeToName(intl, additionalData.addon_product_code),
          }),
          linkText: intl.t('subscription.change.blockers.addon.link'),
          description: intl.t('subscription.change.blockers.addon-billing.subtitle'),
          blockerTrackEvent: {
            blockerType: 'downsize',
            addon_code: additionalData.addon_product_code,
          },
        };
    }
  }

  bankAccountCountToDisable(bankAccountLimit) {
    let { organization } = this.subscription;
    let bankAccounts = organization.get('bankAccounts');

    if (bankAccounts) {
      return (
        bankAccounts.filter(({ status }) => status === 'active' || status === 'pending').length -
        bankAccountLimit
      );
    }
  }

  @action
  transitionTo(selectedRoute, blockerTrackEvent) {
    this.args.close();
    if (selectedRoute?.name === 'addon-change') {
      this.segment.track('addon_blocker_clicked', blockerTrackEvent);
      this.flowLinkManager.transitionTo({
        name: 'addon-change',
        stepId: 'addons',
        queryParams: {
          addon_recurrence: selectedRoute.recurrence,
        },
      });
    } else {
      this.router.transitionTo(selectedRoute);
    }
    let { pricePlan, product, organization, recurrence } = this.subscription;

    let data;
    if (this.hasModularPricing) {
      data = {
        code: product.get('code'),
        orgId: organization.get('id'),
        recurrence,
      };
    } else {
      data = {
        code: pricePlan.get('code'),
        orgId: organization.get('id'),
        recurrence,
      };
    }

    safeLocalStorage.setItem('price-plan-change-in-progress', JSON.stringify(data));
  }
}
