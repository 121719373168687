export default {
  "invoice-sidebar": "cnv",
  "body": "cno",
  "box": "cnn",
  "box-header": "cni",
  "footer": "cnA",
  "footer-button": "cnY",
  "footer-button-tooltip": "cnh",
  "sidebar-box": "cnf",
  "border-top": "cnK",
  "row": "cnG",
  "status-container": "cnr",
  "due-date-container": "cnb",
  "greyed-out": "cny",
  "struck-through": "cnU",
  "overdue": "cnj",
  "primary-actions": "cnW cnf",
  "primary-action": "cnu",
  "danger-action": "ciS",
  "button-icon": "cic",
  "button-label": "ciq body-2",
  "related-credit-note-title": "ciZ caption-bold",
  "paid-at-placeholder": "ciR"
};
