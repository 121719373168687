export default {
  "cell-content": "cju",
  "icon": "cWS",
  "status_check-m": "cWc",
  "description": "cWq body-1",
  "from": "cWZ body-2",
  "from--abort": "cWR",
  "description--abort": "cWQ",
  "title": "cWe body-2"
};
