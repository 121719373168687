export default {
  "form-content": "cYQ",
  "row": "cYe",
  "numbering-format": "cYB",
  "numbering-fields": "cYE",
  "input-settings": "cYd",
  "next-invoice-number": "cYI",
  "spacing": "cYt",
  "label-tooltip": "cYM",
  "tooltip-icon": "cYP",
  "customize-field": "cYl"
};
