export default {
  "header": "crN",
  "header-type": "crv",
  "close": "cro",
  "close-icon": "crn",
  "header-infos": "cri",
  "title": "crA body-1",
  "declined": "crY",
  "canceled": "crh",
  "approved": "crf",
  "pending": "crK",
  "date": "crG body-2",
  "container": "crr",
  "picto": "crb",
  "picto-status": "cry",
  "general": "crU",
  "amount": "crj",
  "counterparty": "crW body-2",
  "initiator": "cru",
  "initiator-avatar": "cbS",
  "avatar-image": "cbc",
  "initiator-id": "cbq",
  "name": "cbZ body-2",
  "role": "cbR caption-bold",
  "infobox": "cbQ",
  "account-infobox": "cbe cbQ",
  "beneficiary-warning": "cbB caption-bold",
  "dates-wrapper": "cbE",
  "dates": "cbd",
  "recurrence": "cbI caption-bold",
  "arrow": "cbt",
  "notify-checkbox": "cbM"
};
