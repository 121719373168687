export default {
  "row": "cGh body-1",
  "active": "cGf",
  "cell": "cGK",
  "empty": "cGG",
  "ellipsis": "cGr",
  "text-secondary": "cGb",
  "transfer-type": "cGy",
  "transfer-type__icon": "cGU",
  "quick-actions": "cGj",
  "amount": "cGW",
  "cell-approve": "cGu",
  "account-select": "crS",
  "row-compact": "crc",
  "cell-amount": "crq",
  "cell-content-receipt": "crZ body-1",
  "cell-date": "crR",
  "row-sidebar": "crQ",
  "hidden": "cre",
  "animated": "crB",
  "fadein-item": "crE",
  "animated-cell": "crd",
  "note": "crI"
};
