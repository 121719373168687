export default {
  "row": "cVa",
  "terminated": "cVH",
  "status-avatar": "cVO",
  "amount": "cVm body-1",
  "item-info": "cVp",
  "date-cell": "cVx",
  "cell": "cVw body-2",
  "status-icon": "cVN",
  "amount-cell": "cVv",
  "item-name-cell": "cVo",
  "item-name-container": "cVn"
};
