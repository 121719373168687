export default {
  "wrapper": "chz",
  "tabs": "cha",
  "tabs-nav": "chH",
  "tabs-panel": "chO",
  "tabs-panel-wrapper": "chm",
  "list-head": "chp",
  "list": "chx",
  "footer": "chw",
  "reward-amount": "chN",
  "rewarded": "chv",
  "registered": "cho",
  "disclaimer": "chn",
  "empty-state-wrapper": "chi",
  "empty-state": "chA",
  "illustration": "chY",
  "spinner": "chh",
  "total-earned": "chf"
};
