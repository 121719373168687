export default {
  "card": "cLL",
  "header": "cLT",
  "avatar": "cLz",
  "title": "cLa",
  "subtitle": "cLH",
  "label": "cLO",
  "item": "cLm body-2",
  "header-icon": "cLp",
  "mt-8": "cLx"
};
