export default {
  "header": "cbN",
  "header-type": "cbv",
  "close": "cbo",
  "close-icon": "cbn",
  "header-infos": "cbi",
  "title": "cbA body-1",
  "declined": "cbY",
  "canceled": "cbh",
  "approved": "cbf",
  "pending": "cbK",
  "date": "cbG body-2",
  "mileage-icon": "cbr",
  "distance-subtitle": "cbb body-2",
  "link-icon": "cby",
  "container": "cbU",
  "picto": "cbj",
  "general": "cbW",
  "amount": "cbu",
  "calculation-container": "cyS",
  "counterparty": "cyc body-2",
  "infobox": "cyq"
};
