export default {
  "wrapper": "cUA",
  "card-header": "cUY",
  "subtitle-caption": "cUh",
  "estimated-earnings": "cUf",
  "progress": "cUK",
  "progress-bar": "cUG",
  "progress-bar-text": "cUr",
  "subtitle": "cUb",
  "badge": "cUy",
  "core-information": "cUU",
  "secondary-information": "cUj",
  "remuneration-table": "cUW",
  "name": "cUu title-3",
  "separator": "cjS",
  "remuneration-line": "cjc",
  "german-disclaimer": "cjq",
  "table": "cjZ",
  "table-right": "cjR"
};
