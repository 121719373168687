export default {
  "header": "qtB",
  "subtitle": "qtE body-2",
  "date": "qtd",
  "subtitle-error": "qtI",
  "card": "qtt",
  "card-header": "qtM",
  "line-placeholder": "qtP",
  "post-closing-list": "qtl",
  "post-closing-container": "qtX",
  "post-closing-list-element": "qtC",
  "free-trial-disclaimer": "qtk",
  "description": "qtJ body-2",
  "mw-100": "qts",
  "addon-overview": "qtF",
  "addons-container": "qtg"
};
