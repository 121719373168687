export default {
  "modal": "qPN",
  "action-container": "qPv",
  "similar-label": "qPo",
  "info-icon": "qPn",
  "container": "qPi",
  "subtitle": "qPA caption-bold",
  "error": "qPY",
  "item-container": "qPh"
};
