export default {
  "header-cell": "qCA",
  "header-content": "qCY caption-bold",
  "active": "qCh",
  "empty": "qCf",
  "align-right": "qCK",
  "type": "qCG",
  "requester": "qCr",
  "request-date": "qCb",
  "amount": "qCy",
  "status": "qCU"
};
