export default {
  "supplier-selector-wrapper": "qMA",
  "select-supplier": "qMY",
  "iban": "qMh",
  "card": "qMf body-2",
  "add-btn": "qMK btn btn--square btn--icon-only",
  "archived-card": "qMG",
  "title": "qMr body-1",
  "archived-title": "qMb",
  "edit-supplier-tooltip": "qMy",
  "add-supplier": "qMU btn btn--tertiary",
  "error": "qMj caption"
};
