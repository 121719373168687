export default {
  "section-container": "cKJ",
  "disclaimer-section": "cKs",
  "trip-title": "cKF caption-bold mb-16",
  "google-link-container": "cKg",
  "link-icon": "cKD",
  "trip-details": "cKV body-2",
  "trip-details-icon": "cKL",
  "trip-details-title": "cKT",
  "subtitle": "cKz caption-bold mb-16",
  "disclaimer": "cKa",
  "visualizer": "cKH"
};
