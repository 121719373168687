export default {
  "wrapper": "qRW",
  "header": "qRu mb-48",
  "form-title": "qQS title-2 mb-16",
  "form-subtitle": "qQc body-2",
  "form": "qQq",
  "section": "qQZ mb-48",
  "section-title": "qQR title-3 mb-16",
  "form-footer": "qQQ",
  "footer-btn": "qQe",
  "transfer-form": "qQB"
};
