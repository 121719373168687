export default {
  "item": "cAz",
  "details": "cAa",
  "flex": "cAH",
  "header": "cAO cAH",
  "flex-no-gap": "cAm cAH",
  "file-name": "cAp",
  "label": "cAx body-2",
  "self-icon": "cAw",
  "with-separator": "cAN",
  "dimmed": "cAv"
};
