export default {
  "header": "qSz",
  "header-type": "qSa",
  "close": "qSH",
  "close-icon": "qSO",
  "header-infos": "qSm",
  "title": "qSp body-1",
  "declined": "qSx",
  "canceled": "qSw",
  "approved": "qSN",
  "pending": "qSv",
  "date": "qSo body-2",
  "mileage-icon": "qSn",
  "distance-subtitle": "qSi body-2",
  "link-icon": "qSA",
  "container": "qSY",
  "picto": "qSh",
  "general": "qSf",
  "amount": "qSK",
  "calculation-container": "qSG",
  "counterparty": "qSr body-2",
  "infobox": "qSb"
};
