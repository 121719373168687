export default {
  "insight-pill": "qPd",
  "insight-pill-figure": "qPI",
  "insight-pill-renderer": "qPt",
  "loading": "qPM",
  "counter": "qPP",
  "error": "qPl",
  "insight-pill-label": "qPX",
  "insight-pill-subtitle": "qPC caption",
  "hidden": "qPk",
  "missing-amounts-warning": "qPJ",
  "missing-amounts": "qPs"
};
