export default {
  "container": "cYX",
  "content": "cYC",
  "input-row": "cYk",
  "email-row": "cYJ",
  "button-row": "cYs",
  "btn-edit": "cYF",
  "input-column": "cYg",
  "form-btn-row": "cYD",
  "border-bottom": "cYV",
  "avatar-input": "cYL"
};
