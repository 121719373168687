export default {
  "wrapper": "qRN",
  "beneficiary": "qRv",
  "name": "qRo body-1",
  "beneficiary-email": "qRn body-2",
  "dropdown-icon": "qRi",
  "trusted": "qRA",
  "account-number": "qRY flex body-2",
  "account-icon": "qRh",
  "m-chip": "qRf",
  "dropdown": "qRK body-2",
  "delete-option": "qRG",
  "beneficiary-name": "qRr"
};
