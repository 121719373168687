export default {
  "header": "cuD",
  "header-type": "cuV",
  "close": "cuL",
  "close-icon": "cuT",
  "header-infos": "cuz",
  "title": "cua body-1",
  "declined": "cuH",
  "canceled": "cuO",
  "approved": "cum",
  "pending": "cup",
  "date": "cux body-2",
  "container": "cuw",
  "picto": "cuN",
  "picto-status": "cuv",
  "general": "cuo",
  "amount": "cun",
  "counterparty": "cui body-2",
  "initiator": "cuA",
  "initiator-avatar": "cuY",
  "avatar-image": "cuh",
  "initiator-id": "cuf",
  "name": "cuK body-2",
  "role": "cuG caption-bold",
  "infobox": "cur",
  "account-infobox": "cub cur",
  "beneficiary-warning": "cuy caption-bold",
  "dates-wrapper": "cuU",
  "dates": "cuj",
  "recurrence": "cuW caption-bold",
  "arrow": "cuu",
  "notify-checkbox": "qSS"
};
