export default {
  "invoice-sidebar": "qEx",
  "body": "qEw",
  "box": "qEN",
  "box-header": "qEv",
  "sidebar-box": "qEo",
  "border-top": "qEn",
  "row": "qEi",
  "greyed-out": "qEA",
  "strike-through": "qEY"
};
