export default {
  "row": "cWB",
  "empty": "cWE",
  "active": "cWd",
  "animated": "cWI",
  "fadein-item": "cWt",
  "cell": "cWM body-2",
  "cell-content": "cWP",
  "cell-amount": "cWl",
  "cell-content-amount": "cWX body-1"
};
