export default {
  "company-logo": "cDT",
  "list-item": "cDz",
  "link": "cDa",
  "title-wrapper": "cDH",
  "title": "cDO body-2",
  "subtitle": "cDm caption",
  "truncated-text": "cDp",
  "image-placeholder": "cDx"
};
