export default {
  "row": "qIe",
  "disabled": "qIB",
  "cell": "qIE body-2",
  "cell-link": "qId",
  "cell-content": "qII",
  "item-truncate-text": "qIt",
  "item-name-cell": "qIM",
  "item-account-cell": "qIP",
  "icon": "qIl",
  "icon-download": "qIX"
};
