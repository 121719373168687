export default {
  "container": "caq",
  "indicator-container": "caZ",
  "transactions-container": "caR",
  "indicator": "caQ",
  "cashflow-container": "cae",
  "cashflow": "caB",
  "donut-container": "caE",
  "donut": "cad"
};
