export default {
  "referral-invite": "chK",
  "referral": "chG",
  "illustration": "chr",
  "steps": "chb",
  "step": "chy",
  "share-referral": "chU",
  "choice": "chj",
  "line": "chW",
  "email": "chu",
  "email-input": "cfS",
  "input-field--error": "cfc",
  "error-message": "cfq",
  "external-link-icon": "cfZ"
};
