export default {
  "row": "cfi",
  "active": "cfA",
  "animated": "cfY",
  "fadein-item": "cfh",
  "cell": "cff",
  "cell-content": "cfK",
  "row-sidebar": "cfG",
  "empty": "cfr",
  "cell-amount": "cfb",
  "subtitle": "cfy caption",
  "hidden": "cfU",
  "cell-approve": "cfj",
  "quick-actions": "cfW",
  "account-select": "cfu",
  "note": "cKS",
  "cell-content-amount": "cKc body-1",
  "cell-status": "cKq"
};
