export default {
  "row": "cYh",
  "cell": "cYf",
  "greyed-out": "cYK",
  "struck-through": "cYG",
  "flex-cell": "cYr",
  "overdue": "cYb",
  "align-right": "cYy",
  "customer-name-container": "cYU",
  "icon": "cYj",
  "warning": "cYW",
  "with-separator": "cYu",
  "align-center": "chS"
};
