export default {
  "numbering-container": "coB",
  "automatic-number-label": "coE",
  "icon-tooltip": "cod",
  "fix-width": "coI",
  "dates-container": "cot",
  "with-performance-date": "coM",
  "date-picker": "coP",
  "label": "col",
  "header-text-field-container": "coX",
  "mt-16": "coC",
  "header-text-field": "cok",
  "header-text-field-close-button": "coJ"
};
