export default {
  "bullet": "czm",
  "slice-0": "czp",
  "slice-1": "czx",
  "slice-2": "czw",
  "slice-3": "czN",
  "slice-4": "czv",
  "slice-5": "czo",
  "slice-6": "czn",
  "slice-other": "czi",
  "slice-uncategorized": "czA",
  "label": "czY",
  "ellipsis": "czh",
  "rest": "czf",
  "previous-date-range": "czK caption"
};
