export default {
  "header": "cKO",
  "header-type": "cKm",
  "close": "cKp",
  "close-icon": "cKx",
  "header-infos": "cKw",
  "title": "cKN body-1",
  "declined": "cKv",
  "canceled": "cKo",
  "approved": "cKn",
  "pending": "cKi",
  "date": "cKA body-2",
  "mileage-icon": "cKY",
  "distance-subtitle": "cKh body-2",
  "link-icon": "cKf",
  "container": "cKK",
  "picto": "cKG",
  "general": "cKr",
  "amount": "cKb",
  "calculation-container": "cKy",
  "counterparty": "cKU body-2",
  "infobox": "cKj"
};
