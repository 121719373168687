export default {
  "insight-pill": "qMW",
  "interactive": "qMu",
  "focus": "qPS",
  "subtitle": "qPc caption",
  "hidden": "qPq",
  "missing-amounts-warning": "qPZ",
  "totals": "qPR",
  "counter": "qPQ title-3",
  "loading": "qPe",
  "error": "qPB",
  "title": "qPE title-4"
};
