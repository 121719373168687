export default {
  "wrapper": "caH",
  "header": "caO",
  "fadeIn": "cam",
  "header-content": "cap",
  "with-cta": "cax",
  "header-title": "caw title-4",
  "advanced-filters-trigger": "caN",
  "filters-button": "cav",
  "filters-button-upsell": "cao",
  "filters-upsell-item": "can",
  "filters-badge": "cai",
  "chart-container": "caA",
  "chart-legend-container": "caY mb-24",
  "chart-watermark-container": "cah",
  "chart-watermark": "caf",
  "placeholder-title": "caK",
  "placeholder-subtitle": "caG",
  "advanced-filters": "car"
};
